<template>
  <v-container class="box">
    <v-row justify="center" class="mb-3" no-gutters>
      <v-img
        :src="require('@/assets/logoHD.png')"
        max-height="100"
        max-width="250"
        aspect-ratio="1"
        class="mx-auto"
      ></v-img>
    </v-row>
    <v-col
      v-for="(item, n) in aboutData"
      :key="n"
      class="ma-0 pa-3"
      align-self="center"
      justify="center"
    >
      <v-row v-if="n % 2 !== 0">
        <v-col class="imgLeft" v-show="$vuetify.breakpoint.mdAndUp">
          <v-img
            :src="require(`@/assets/${item.img}`)"
            aspect-ratio="1"
            max-height="400"
            max-width="800"
            class="ml-auto fill-height"
          >
          </v-img>
        </v-col>
        <v-col class="ma-0 pa-0" align-self="center" justify="center">
          <div class="pl-2 pr-2 text-center text-h4 font-weight-medium">
            {{ item.title }}
          </div>
          <div
            class="pl-2 pr-2 text-center text-subtitle-1 font-weight-regular"
          >
            {{ item.subtitle }}
          </div>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="ma-0 pa-0" align-self="center" justify="center">
          <div class="pl-2 pr-2 text-center text-h4 font-weight-medium">
            {{ item.title }}
          </div>
          <div
            class="pl-2 pr-2 text-center text-subtitle-1 font-weight-regular"
          >
            {{ item.subtitle }}
          </div>
        </v-col>
        <v-col class="imgRight" v-show="$vuetify.breakpoint.mdAndUp">
          <v-img
            :src="require(`@/assets/${item.img}`)"
            aspect-ratio="1"
            max-height="400"
            max-width="800"
            class="mr-auto fill-height"
          >
          </v-img>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>
<script>
export default {
  name: "template1",
  data() {
    return {};
  },
  props: {
    aboutData: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.box {
  width: 65vw;
}
.imgLeft {
  padding: 0;
  display: inline-block;
}
.imgRight {
  padding: 0;
  display: inline-block;
}
</style>
