<template>
  <v-container>
    <about-template-1 :aboutData="aboutDataT1"></about-template-1>
  </v-container>
</template>
<script>
import AboutTemplate1 from "../UI/AboutTemplate1.vue";
export default {
  components: { AboutTemplate1 },
  name: "about",
  data() {
    return {
      aboutDataT1: [
        {
          title: "Все для вас",
          subtitle:
            "BPO.Travel — актуальный сервис по покупке Авиа билетов по всему миру.",
          img: "airplanes/1.png",
        },
        {
          title: "Компания с большой историей",
          subtitle:
            "Свою деятельность компания bpo.travel начала в 2003 году. За это время были открыты 20 торговых точек , которые размещены в Москве и городах Подмосковья.",
          img: "airplanes/2.png",
        },
        {
          title: "Всегда с вами",
          subtitle:
            "Наша цель — организация наиболее комфортного и благоприятного передвижения пассажира, а также создание условий высококачественного пребывания в любой точке мира!",
          img: "airplanes/3.png",
        },
        {
          title: "Качественный сервис",
          subtitle:
            "Наша компания послужит Вам удобным сервисом для бронирования авиационных и железнодорожных билетов, проездных билетов, приобретения посадочных мест в автобусе, услуги трансфера, визовое обслуживание, страхование на время поездки, а также резервирование гостиничных номеров в любой городе мира.",
          img: "airplanes/4.png",
        },
        {
          title: "Заботимся о клиентах",
          subtitle:
            "Сотрудничество со множеством авиакомпаний дает нам возможность подобрать для Вас наиболее оптимальный вариант отправления по лучшей цене.",
          img: "airplanes/5.png",
        },
        {
          title: "Всегда на связи",
          subtitle:
            "Круглосуточная поддержка ответит на любые вопросы и окажет помощь в любой ситуации.",
          img: "airplanes/6.png",
        },
      ],
    };
  },
  mounted() {
    document.title = "О нас";
  },
  methods: {},
};
</script>

<style scoped>
.title {
  color: #004ea6;
}
</style>
